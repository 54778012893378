<template>
    <vs-popup
      class="order-for-modal"
      title="Order For"
      :active.sync="popupActive">
        <!-- Organizations  -->
        <div class="relative">
          <label for="selectedOrg">Organization</label>
          <multiselect
            id="selectedOrg"
            class="custom-multiselect"
            v-model="data.organizationId"
            :options="organizationsList"
            :loading="!!searching.orgs"
            :clear-on-select="false"
            label="name"
            track-by="_id"
            placeholder="Select Organization"
            @search-change="searchOrganization"
            :max-height="200"
          />
          <vs-icon
            v-if="data.organizationId"
            icon="close"
            color="red"
            bg="white"
            class="removeSelectedFilters"
            @click="data.organizationId = null">
          </vs-icon>
        </div>
        <!-- Clinics  -->
        <div class="relative mt-2">
          <label for="selectedClinic">Clinics</label>
          <multiselect
            id="selectedClinic"
            class="custom-multiselect"
            v-model="data.clinicId"
            :options="clinics"
            :loading="!!searching.clinics"
            :disabled="!data.organizationId"
            :clear-on-select="false"
            label="clinicName"
            track-by="_id"
            placeholder="Select Clinic"
            @search-change="searchClinics"
            :max-height="200"
          />
          <vs-icon
            v-if="data.clinicId"
            icon="close"
            color="red"
            bg="white"
            class="removeSelectedFilters"
            @click="data.clinicId = null">
          </vs-icon>
        </div>
        <!-- Requestor  -->
        <div class="relative mt-2">
          <label for="selectedUser">Requestor</label>
          <multiselect
            id="selectedUser"
            class="custom-multiselect"
            v-model="data.staffId"
            :options="staff"
            :loading="!!searching.users"
            :disabled="!data.organizationId"
            :clear-on-select="false"
            label="fullName"
            track-by="userId"
            placeholder="Select Staff"
            @search-change="searchStaffs"
            :max-height="200"
          />
          <vs-icon
            v-if="data.staffId"
            icon="close"
            color="red"
            bg="white"
            class="removeSelectedFilters"
            @click="data.staffId = null">
          </vs-icon>
        </div>
        <vs-button class="mt-4" color="primary" @click="submit">Submit</vs-button>
    </vs-popup>
</template>

<script>
import { mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  name: "AdminClinicSelect",
  components: {
    Multiselect
  },
  props: [
    "popupActive",
    "propClinicId"
  ],
  data() {
    return {
      searching: {
        orgs: null,
        clinics: null,
        users: null
      },
      organizationsList: [],
      clinics: [],
      staff:[],
      data:{
        organizationId: "",
        clinicId: "",
        staffId: ""
      }
    };
  },
  methods: {
    ...mapActions("organization", [
      "fetchOrganizationClinicsList",
      "fetchOrganizations",
      "fetchOrganizationStaffWithStorePermission"
    ]),
    closeModal() {
      this.$emit("closeModal");
    },
    organizationChange(val) {
      this.$emit("onOrganizationChange", val);
    },
    submit() {
      if(!this.data.organizationId || this.data.organizationId === "") {
        this.$vs.notify({
          title: "Error",
          text: "Please select organization",
          color: "danger",
          position: "top-right"
        });
        return
      }
      if(!this.data.clinicId || this.data.clinicId === "") {
        this.$vs.notify({
          title: "Error",
          text: "Please select clinic",
          color: "danger",
          position: "top-right"
        });
        return
      }
      if(!this.data.staffId || this.data.staffId === "") {
        this.$vs.notify({
          title: "Error",
          text: "Please select requestor",
          color: "danger",
          position: "top-right"
        });
        return
      }
      this.organizationChange(this.data.organizationId);
      setTimeout(() => {
        this.$emit("onSubmit", {
          clinicId: this.data.clinicId,
          staffId: this.data.staffId
        });
      }, 500);
    },
    async getOrganizationsList(search = '') {
        const { data } = await this.fetchOrganizations({
          page:1,
          limit:200,
          search,
          sort:"name",
          dir:"asc",
          filter:"All"
        });
        this.organizationsList = data.data.docs;
        this.searching.orgs = null;
    },
    async getClinics(val, search = '') {
      const { data } = await this.fetchOrganizationClinicsList({
        id: val,
        limit: 200,
        search,
        sort: "clinicName",
        dir: "asc",
      });
      this.clinics = data.data.docs.map(
        ({
          _id,
          clinicName,
          clinicOpeningHours,
          address,
          paymentTypes,
          bestContactName,
          bestContactNumber,
          bestContactEmail,
          status,
          storeInvoiceEmail,
        }) => ({
          _id,
          clinicName,
          clinicOpeningHours,
          address,
          paymentTypes,
          bestContactName,
          bestContactNumber,
          bestContactEmail,
          status,
          storeInvoiceEmail,
        })
      );
      this.searching.clinics = null;
    },
    async getStaffList(val, search = '') {
      const { data } = await this.fetchOrganizationStaffWithStorePermission({
        organizationId: val,
        search
      })
      this.staff = data.data.docs.map(({ userId, fullName }) => ({ userId, fullName }));
      this.searching.users = null;
      if (this.staff.length === 0) {
        return
      }

      const selectedIds = JSON.parse(localStorage.getItem('selectedIds'))

      if (
        this.data.organizationId === localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`) &&
        selectedIds.staffId.userId === data.data.docs[0].userId
      ) {
        this.data.staffId = selectedIds.staffId;
        return
      }

      this.data.staffId = this.staff[0];
    },
    searchOrganization(query, _) {
      if (this.searching.orgs) clearTimeout(this.searching.orgs);

      this.searching.orgs = setTimeout(() => {
        this.getOrganizationsList(query)
      }, 2000)
    },
    searchClinics(query, _) {
      if (this.searching.clinics) clearTimeout(this.searching.clinics);

      this.searching.clinics = setTimeout(() => {
        this.getClinics(this.data.organizationId._id, query);
      }, 2000)
    },
    searchStaffs(query, _) {
      if (this.searching.users) clearTimeout(this.searching.users);

      this.searching.users = setTimeout(() => {
        this.getStaffList(this.data.organizationId._id, query);
      }, 2000)
    }
  },
  created() {
    if (this.$store.state.AppActiveUser.userType === "superAdmin") {
      const selectedIds = JSON.parse(localStorage.getItem('selectedIds'))
      this.data.organizationId = JSON.parse(localStorage.getItem('selectedOrg'))

      if (selectedIds) {
        this.data.clinicId = selectedIds.clinicId
        this.data.staffId = selectedIds.staffId
      }

      if (this.data.organizationId) {
        this.getClinics(this.data.organizationId._id);
        this.getStaffList(this.data.organizationId._id);
      }
    }
    this.getOrganizationsList();
  },
  watch:{
    "data.organizationId": function(val, oldVal) {
      const selectedIds = JSON.parse(localStorage.getItem('selectedIds'))

      if (!val) {
        this.data.clinicId = ""
        this.data.staffId = ""
        return
      }

      if (val !== oldVal) {
        if (oldVal === "" && oldVal !== localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)) {
          this.data.clinicId = selectedIds ? selectedIds.clinicId : ""
        } else {
          this.data.clinicId = "";
        }

        this.data.staffId = "";
        this.getClinics(val._id);
        this.getStaffList(val._id);
      }
    },
  }
};
</script>

<style>
.order-for-modal .vs-popup--header .vs-popup--title h3 {
  display: block !important;
}

.order-for-modal .vs-popup .vs-popup--content {
  padding: 1rem !important;
  margin: 5px 5px 5px !important;
}


.custom-multiselect .multiselect__tag, .multiselect__option--highlight {
  background-color: #3CC9B2;
  color: white;
}

.custom-multiselect .multiselect__content-wrapper {
  max-height: 200px;
  overflow-y: auto;
  position: fixed !important;
  z-index: 99999;
  width: 100%;
  max-width: 567px;
}

.removeSelectedFilters {
  position: absolute;
  right: 35px;
  bottom: 12px;
  cursor: pointer;
}
</style>
